<template>
  <div class="content-wrapper">
    <div class="sns-setting main-content">
      <div class="page-header">
        <h1>SNS Setting</h1>
      </div>
      <div class="form-wrapper">
        <div class="action-button-wrapper">
          <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
          <button v-if="!disabled" class="btn btn-primary saveButton" @click="updateSettings">Save</button>
        </div>

        <h2 class="card-title">Facebook Setting</h2>

        <div class="card info-card">
          <el-form ref="facebook-setting-form" class="facebook-setting-form" :disabled="disabled" :model="form" label-width="200px">
            <div class="row">
              <el-form-item label="Facebook Access Token" class="facebook-access-token">
                <el-input v-if="!showTokenField" type="password" @focus="initTokenField" v-model="form.facebookAccessToken"></el-input>
                <el-input v-if="showTokenField" v-model="form.facebookAccessToken"></el-input>
              </el-form-item>
              <button v-if="!disabled" class="btn btn-primary" @click.prevent="loadFacebookPages">Load Facebok Pages List</button>
            </div>
            <div class="row">
              <el-form-item label="Facebook Pages" v-if="facebookPages.length !== 0">
                <el-select v-model="form.facebookPage" placeholder="Please select page">
                  <el-option
                    v-for="item in facebookPages"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'SNSSetting',
  data(){
    return {
      disabled: true,
      showTokenField: false,
      facebookPages: [],
      form: {
        facebookUserId: '',
        facebookAccessToken: 'Enter your facebook access token',
        facebookPage: '',
      },
    }
  },
  mounted(){
    this.loadSNSSettings();
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    initTokenField(){
      console.log()
      this.showTokenField = true;
      this.form.facebookAccessToken = '';
    },
    async loadFacebookPages(){
      const loginInfo = Common.getLoginInfo();
      const facebookPages = await Sales.loadFacebookPages(this.apiUrl, this.form.facebookAccessToken, loginInfo);
      this.facebookPages = facebookPages;
    },
    async loadSNSSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let facebookPage = await Common.loadConfig(this.apiUrl, 'facebook_page', loginInfo);
        facebookPage = facebookPage['config_value'];
        this.form.facebookPage = facebookPage;

        //If facebook page setting is existed
        if(facebookPage.length !== 0){
          const facebookPages = await Sales.loadFacebookPages(this.apiUrl, null, loginInfo);
          this.facebookPages = facebookPages;
        }
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const facebookPageName = this.form.facebookPage;
        await Common.updateConfig(this.apiUrl, 'facebook_page', facebookPageName, loginInfo);

        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}

.facebook-setting-form{
  .row{
    align-items: flex-start;
    .btn{
      margin-left: 30px;
    }
  }
}
</style>
